import React, { useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

const EndpointSpecContainer = styled.div`
  display: flex;
  border: 2px solid #6049a0;
  border-radius: 6px;
  align-items: center;
  overflow: hidden;

  .swagger-ui {
    width: 100%;
  }

  .swagger-ui .info {
    display: none;
  }
  .swagger-ui tr {
    background-color: transparent;
  }

  .swagger-ui p {
    font-size: inherit;
  }
`;

//return an array of values that match on a certain key
function getValues(obj, key) {
  var objects = [];
  for (var i in obj) {
    if (!obj.hasOwnProperty(i)) continue;

    if (i == key) {
      objects.push(obj[i]);
    } else if (typeof obj[i] == "object") {
      objects = objects.concat(getValues(obj[i], key));
    }
  }
  return objects;
}

function cleanUnwantedPaths(data, wantedPath) {
  const newData = { ...data };
  console.log("old data", data);
  const newPathData = { ...data.paths[wantedPath] };

  Object.keys(newPathData).forEach((elem) => {
    delete newPathData[elem].tags;
  });

  newData.paths = {
    [wantedPath]: data.paths[wantedPath],
  };

  newData.servers = [{
    url: "https://sandbox.tickitto.tech",
  }];

  console.log("new data", newData);

  //filter out so we only use certain schemas
  const refsPresent = getValues(newData.paths, "$ref");
  const refsUsed = [...new Set(refsPresent)];
  const newSchemas = {};
  refsUsed.forEach((elem) => {
    const slug = elem.split("/").pop();
    newSchemas[slug] = data.components.schemas[slug];
  });

  let refsInSchemas = getValues(newSchemas, "$ref") || [];
  while (refsInSchemas.length > 0) {
    refsInSchemas.forEach((elem) => {
      const slug = elem.split("/").pop();
      newSchemas[slug] = data.components.schemas[slug];
    });

    const newNewSchemas = (getValues(newSchemas, "$ref") || []).filter(
      (elem) => {
        const slug = elem.split("/").pop();
        return !Object.keys(newSchemas).includes(slug);
      }
    );

    refsInSchemas = newNewSchemas;
  }

  newData.components.schemas = newSchemas;

  return newData;
}

const EndpointSpec = ({ endpointTag = "/api/authentication/account" }) => {
  const ref = useRef();
  const [swaggerSpec, setSwaggerSpec] = useState();

  //fetch the paths
  useEffect(() => {
    async function getData() {
      const { data } = await axios.get(
        "https://sandbox.tickitto.tech/openapi.json"
      );

      const cleanedData = cleanUnwantedPaths(data, endpointTag);
      setSwaggerSpec(cleanedData);
      return cleanedData;
    }

    getData();
  }, []);

  return (
    <EndpointSpecContainer ref={ref}>
      {swaggerSpec && (
        <SwaggerUI
          spec={swaggerSpec}
          filter={"Events"}
          docExpansion="none"
          onComplete={() => {
            //We previously hide all of the blocks and now we undhide the one we want
            const foundEndpoint = ref.current.querySelector(
              `[data-tag="default"]`
            );

            if (foundEndpoint != null) {
              foundEndpoint.click(); //expand the tags section
              foundEndpoint.style.display = "none";
            } else {
              console.error(
                "We could not find a swagger tag wit the name: " + endpointTag
              );
            }
          }}
        />
      )}
    </EndpointSpecContainer>
  );
};

export default EndpointSpec;
